@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


$background-color: #282c34;

$green-gradient: linear-gradient(45deg, #499bea, #1abc9c);
$green-gradient-hl: linear-gradient(45deg,hsl(209, 79%, 70%), hsl(168, 76%, 52%));
$purple-gradient: linear-gradient(45deg, #e748ea, #4b1abc);
$purple-gradient-hl: linear-gradient(45deg, hsl(299, 79%, 70%), hsl(258, 76%, 52%));
$red-gradient: linear-gradient(45deg, #ea6e48, #bc1a63);
$red-gradient-hl: linear-gradient(45deg, hsl(14, 79%, 70%), hsl(333, 76%, 52%));
$orange-gradient: linear-gradient(45deg,#ffc107, #ff5722);
$orange-gradient-hl: linear-gradient(45deg,hsl(45, 100%, 70%), hsl(14, 100%, 70%));
$blue-gradient: linear-gradient(45deg, #0576ff, #24f8ff);
$blue-gradient-hl: linear-gradient(45deg, hsl(213, 100%, 70%), hsl(182, 100%, 80%));
$pink-blue-gradient: linear-gradient(45deg, pink, skyblue);
$pink-blue-gradient: linear-gradient(45deg, hsl(350, 100%, 90%), hsl(197, 71%, 80%));

body {
  background: #282c34; 
}

.App{
  text-align: center;
  background-color: $background-color;
  // height: 100vh;
  width: 100vw;
  color: white;
  z-index: -2;
}

.App-link {
  color: #61dafb;
}

.header {
  // height: 80px;
  padding: 10px 40px;
  border-bottom: 1px solid rgb(197, 201, 202);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 
  0px 3px 5px -1px rgba(0, 0, 0, 0.2), 
  0px 6px 10px 0px rgba(0, 0, 0, 0.14), 
  0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  &-brand {
    display: flex;
    justify-content: center;

    img {
      height: 50px;
    }
    &-text {
      text-align: left;
      h2 {
        margin: 0 10px;
        font-size: 18px;
      }
    }
  }

}

.displayer {
  height: 60vh;
}
.controller {
  display: flex;
  align-items: center;
  justify-content: center;
  &-count {
    width: 80px;
    height: 80px;
    position: relative;
    background: #12c2e9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(45deg,#fc4a1a, #f7797d, #f7b733,#FBD786, #C6FFDD, #12c2e9, #c471ed, #f64f59, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(45deg,#fc4a1a, #f7797d, #f7b733,#FBD786, #C6FFDD, #12c2e9, #c471ed, #f64f59, #78ffd6, #a8ff78); 
    animation: bg-gradient 10s ease infinite;
    background-size: 1800% 1800%;
    border-radius: 50%;
    transform: translateY(-10%);
    margin: 0 20px;
    box-shadow: 
    0px 3px 5px -1px rgba(0, 0, 0, 0.2), 
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    &-text {
      margin: 0;
      @include absolute-center;
      top: 55%;
      font-size: 30px;
      font-weight: bold;
      // text-shadow: 0px 0px 2px #282c34;
    }
    &-check {
      margin: 0;
      @include absolute-center;
      top: 55%;
      height: 60px; 
      width: 40px;
      // text-shadow: 0px 0px 2px #282c34;
    }

  }
  .fabProgress {
    position: absolute;
    left: -9px;
    top: -9px;
    color: #44f5ab;
    // top: -6px,
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bg-gradient {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}


.gradientButton {
  width: 60px;
  height: 40px;
  font: 2em/1 sans-serif;
  border: double 2px transparent;
  border-radius: 4px;
  background-image: linear-gradient($background-color, $background-color), radial-gradient(circle at bottom left,#0576ff, #24f8ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  transition: all 0.2s ease-out;
  // &:hover {
  //   filter: brightness(1.5);
  // }
}

.blue-gradient {
  background-image: linear-gradient($background-color, $background-color), $blue-gradient;
}
.blue-gradient-hl {
  background-image: linear-gradient($background-color, $background-color), $blue-gradient-hl;
}
.green-gradient {
  background-image: linear-gradient($background-color, $background-color), $green-gradient;
}
.green-gradient-hl {
  background-image: linear-gradient($background-color, $background-color), $green-gradient-hl ;
}
.red-gradient {
  background-image: linear-gradient($background-color, $background-color), $red-gradient;
}
.red-gradient-hl {
  background-image: linear-gradient($background-color, $background-color), $red-gradient-hl ;
}
.orange-gradient {
  background-image: linear-gradient($background-color, $background-color), $orange-gradient;
}
.orange-gradient-hl {
  background-image: linear-gradient($background-color, $background-color), $orange-gradient-hl;
}
.purple-gradient {
  background-image: linear-gradient($background-color, $background-color), $purple-gradient;
}
.purple-gradient-hl {
  background-image: linear-gradient($background-color, $background-color), $purple-gradient-hl;
}

.balls {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: center;
}

.ball {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: linear-gradient(45deg, #ff5252,#ff8a80,#ff4081,#ff80ab, #e040fb,#ea80fc);
  background-size: 600% 600%;
  position: relative;
  margin: 8px;
  box-shadow: 
  0px 3px 5px -1px rgba(0, 0, 0, 0.2), 
  0px 6px 10px 0px rgba(0, 0, 0, 0.14), 
  0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  &-text {
    margin: 0;
    @include absolute-center;
    // top: 55%;
    font-size: 30px;
    font-weight: bold;
    text-shadow: 0px 0px 1px #282c34;
    // transition: all 0.3s ease;
    opacity: 0;
  }
}