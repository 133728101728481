.modal {
  top: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  &-bg {
    position: fixed;
    z-index: 101;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,.6);
  }
  &-content {
    position: fixed;
    z-index: 102;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  &-fallback {
    border-radius: 4px;
    height: 300px;
    width: 400px;
    padding:70px 30px;
    background: #fff;
    .error-text {
      margin-top: 1em;
    }
  }
}

.demo {
  width: 300px;
  height: 200px;
  background: rgb(245, 245, 245);
  border-radius: 4px;
}

.fade-in {
  animation: fadeIn 0.3s;
}
.fade-out {
  animation: fadeOut 0.2s;
}

.fade-in-left {
  animation: fadeIn 2s, fromLeft 1s ;
}
.fade-in-right {
  animation: fadeIn 2s, fromRight 1s ;
}
.fade-in-top {
  animation: fadeIn 0.4s, fromTop 0.4s ;
}
.fade-in-bottom {
  animation: fadeIn 0.4s, fromBottom 0.4s ;
}

.fade-out-left {
  animation: fadeOut 1s ease-out, toLeft 1s ;
}
.fade-out-right {
  animation: fadeOut 1s ease-out, toRight 1s ;
}
.fade-out-top {
  animation: fadeOut 0.2s ease-out, toTop 0.4s ;
}
.fade-out-bottom {
  animation: fadeOut 0.2s ease-out, toBottom 0.4s ;
}


@keyframes fadeIn {
  0% {
    z-index: 99;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fromTop {
  0% {
    transform: translateY(20px);
  }
  100% {

  }
}
@keyframes fromBottom {
  0% {
    transform: translateY( -20px);
  }
  100% {

  }
}

@keyframes toTop {
  0% {
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes toBottom {
  0% {
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes fromLeft {
  0% {
    transform: translateX(-20px);
  }
  100% {
    // transform: translateX(0);
  }
}
@keyframes fromRight {
  0% {
    transform: translateX(20px);
  }
  100% {
    // transform: translateX(0);
  }
}
@keyframes toLeft {
  0% {
  }
  100% {
    transform: translateX(-20px);
  }
}
@keyframes toRight {
  0% {
  }
  100% {
    transform: translateX(20px);
  }
}